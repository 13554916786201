import { graphql } from 'gatsby';
import React, {
  FunctionComponent,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import Scrollspy from 'react-scrollspy';
import slugify from 'slugify';
import SEO from '~/components/Global/SEO';
import Layout from '~/components/Layout';

const slugifyLower = (slug) =>
  typeof slug === 'string' ? slugify(slug, { lower: true }) : '';

const Legal: FunctionComponent = ({ data }): ReactElement => {
  const pageData = data.wpPage;
  const { masthead, hideLegalNav } = data.wpPage.legalPage;
  const legalSections = data.wpPage.legalPage.legalSections.section;
  const [legalSectionsMapped] = useState(
    legalSections.map((ls) => ({
      ...ls,
      slug: slugifyLower(ls.heading),
    }))
  );

  return useMemo(
    () => (
      <Layout>
        <SEO title={pageData.title} />
        {/* masthead */}
        <section className="py-12 my-0 bg-lgrey md:py-24 md:pt-48 md:-mt-24">
          <div className="container">
            <div className="section-tag">{masthead.tag}</div>
            <h1 dangerouslySetInnerHTML={{ __html: masthead.heading }} />
            <div
              className="max-w-md"
              dangerouslySetInnerHTML={{ __html: masthead.content }}
            />
          </div>
        </section>

        <main>
          <section className="py-12 md:py-24">
            <div className="container">
              <div
                className={`flex flex-wrap ${hideLegalNav && 'justify-center'}`}
              >
                {!hideLegalNav && (
                  <aside className="hidden mb-12 md:w-1/4 md:mb-0 md:block">
                    <div className="sticky p-8 scroll-nav top-24">
                      <div className="p-0 mb-0 bg-white section-tag">
                        Contents
                      </div>
                      <ul>
                        <Scrollspy
                          items={legalSectionsMapped.map(({ slug }) => slug)}
                          offset={-120}
                          currentClassName="is-current"
                        >
                          {legalSectionsMapped.map(({ heading, slug }, i) => (
                            <li key={`legallink${slug + i}`}>
                              <a
                                href={`#${slug}`}
                                dangerouslySetInnerHTML={{ __html: heading }}
                              />
                            </li>
                          ))}
                        </Scrollspy>
                      </ul>
                    </div>
                  </aside>
                )}

                <div className="w-full md:w-3/4 md:pl-12 lg:px-24">
                  {legalSectionsMapped.map(({ heading, content, slug }, i) => (
                    <div
                      key={`legal-section-${slug + i}`}
                      className="relative mb-20"
                    >
                      <div
                        id={slug}
                        className="absolute inset-0 pointer-events-none"
                        style={{ top: '-120px' }}
                      />
                      <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                      <div
                        className="my-6 content-section prose prose-content"
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    ),
    []
  );
};
export default Legal;

export const query = graphql`
  query LegalTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      legalPage {
        masthead {
          tag
          heading
          content
        }
        hideLegalNav
        legalSections {
          section {
            heading
            content
          }
        }
      }
    }
  }
`;
